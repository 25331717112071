import { Box, IconButton, makeStyles, useTheme } from "@material-ui/core";
import Image from "next/image";
import router from "next/router";
import { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { MdLightMode, MdNightlight } from "react-icons/md";
import { useLocalStorage, useToggle } from "react-use";

import { FLEX_CENTER_CSS, H_100_CSS } from "/common/Const/Css";
import { commonOnSuccess, pxFn, pxToRemConvertFn } from "/common/Fn";
import { commonImagePropsFn } from "/common/Image";
import {
  IdStartAdornment,
  PassWordEndAdornment,
  PassWordStartAdornment,
} from "/components/common/Adornment";
import { MainColorButton } from "/components/common/Button/MainButton";
import ControlTextField from "/components/common/Mui/Control/ControlTextField";
import { ColorModeContext } from "/contextAPI";
import FindPwd from "/feature/mobile/login/mobileContents/findPwd";
import useLogIn from "/hooks/api/useMutation/login/useLogIn";
import useGetMenuList from "/hooks/api/useMutation/menu/useGetMenuList";
import useModal from "/hooks/useModal";
import getServerSideProps from "/lib/serverProps";

const LOGIN_MAIN_COLOR_BTN_PROPS = {
  type: "submit",
  text: "로그인",
  width: pxFn(496),
  height: pxFn(52),
};

/**
 * - PC 로그인 페이지
 * - path: /pages/login
 * @returns {JSX.Element}
 * @constructor
 */

const PC_Login_Page = () => {
  const theme = useTheme();
  const classes = useStyles();

  const { showAlert } = useModal();
  const colorMode = useContext(ColorModeContext);

  const [_userInfo, setUserInfo] = useLocalStorage("userInfo");
  const [_menuList, setMenuList] = useLocalStorage("menuList");

  const { handleSubmit, control } = useForm();

  const [idFocus, idFocusToggle] = useToggle(true);
  const [pwFocus, pwFocusToggle] = useToggle(false);

  // pw state
  const [findPwdOpen, findPwdToggle] = useToggle(false);
  const [showPassword, showPasswordToggle] = useToggle(false);

  const CONTROL_TEXT_FIELD_COMMON_PROPS = useMemo(
    () => ({
      control,
      className: "",
      variant: "outlined",
      InputProps: {
        style: {
          width: pxFn(496),
          height: pxFn(48),
          borderRadius: pxFn(8),
          marginBottom: pxFn(12),
        },
      },
      inputProps: { style: { fontSize: pxToRemConvertFn(14) } },
    }),
    [control],
  );

  const { mutate: signInMutate } = useLogIn({
    onSuccess: ({ header, body }) => {
      const onSuccessFn = () => {
        const {
          userInfoDTO: { userId: id, storeNm, userTypeCd, marketId },
        } = body;

        switch (userTypeCd) {
          case "AD":
            setUserInfo({
              id,
              storeNm,
              userTypeCd,
              marketId,
            });
            return getMenuMutate("admin");
          case "SL":
            if (window?.Cypress) {
              setUserInfo({
                id,
                storeNm,
                userTypeCd,
                marketId,
              });

              return router.push("/m/main");
            } else {
              return showAlert({
                width: pxFn(400),
                text: "PC에서 점포로 로그인 할 수 없습니다.",
              });
            }
          case "DV":
            return showAlert({
              width: pxFn(400),
              text: "PC에서 퀵으로 로그인 할 수 없습니다.",
            });
        }
      };

      const onClickAlertBtn = () => {
        if (header?.resMsg?.includes("패스워드")) {
          idFocusToggle(false);
          pwFocusToggle(true);
          showPasswordToggle(true);
        } else {
          idFocusToggle(true);
          pwFocusToggle(false);
        }
      };

      return commonOnSuccess(header, onSuccessFn, onClickAlertBtn);
    },
  });

  const { mutate: getMenuMutate } = useGetMenuList({
    onSuccess: ({ header, body }) => {
      const onSuccessFn = () => {
        setMenuList(
          body?.map(({ menuNm, imgUrl, menuUrl }) => ({
            menuNm,
            imgUrl,
            menuUrl,
          })),
        );

        return router.push("/home");
      };

      return commonOnSuccess(header, onSuccessFn);
    },
  });

  const onSubmit = async (data) => {
    idFocusToggle(false);
    pwFocusToggle(false);

    return signInMutate({
      userInfoDTO: { loginId: data?.loginId || "", password: data?.password || "" },
    });
  };

  useEffect(() => {
    const mode = localStorage.getItem("mode");
    const addrList = localStorage.getItem("addrList");
    localStorage.clear();
    localStorage.setItem("mode", mode);
    localStorage.setItem("addrList", addrList);
  }, []);

  return (
    <div className={classes.loginWrapper}>
      <Box className={classes.darkMode}>
        <IconButton
          onClick={colorMode?.toggleColorMode}
          color="inherit">
          {theme.palette.mode === "dark" ? (
            <MdLightMode color={theme.palette.grey[500]} />
          ) : (
            <MdNightlight />
          )}
        </IconButton>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.loginContainer}>
          {/*logo*/}
          <div className={"loginTop"}>
            <Image
              alt={""}
              {...commonImagePropsFn.logo(262, 28)}
            />
          </div>

          {/*login inputs*/}
          <div className={classes.loginInputs}>
            <Box mb={2}>
              <ControlTextField
                {...CONTROL_TEXT_FIELD_COMMON_PROPS}
                control={control}
                name={"loginId"}
                focus={idFocus}
                placeholder={"아이디"}
                InputProps={{
                  ...CONTROL_TEXT_FIELD_COMMON_PROPS.InputProps,
                  startAdornment: <IdStartAdornment />,
                }}
                data-cy={"id"}
              />
            </Box>

            <Box mb={2}>
              <ControlTextField
                {...CONTROL_TEXT_FIELD_COMMON_PROPS}
                autoComplete={"new-password"}
                type={showPassword ? "type" : "password"}
                control={control}
                name={"password"}
                focus={pwFocus}
                placeholder={"비밀번호"}
                InputProps={{
                  ...CONTROL_TEXT_FIELD_COMMON_PROPS.InputProps,
                  startAdornment: <PassWordStartAdornment />,
                  endAdornment: <PassWordEndAdornment {...{ showPassword, showPasswordToggle }} />,
                }}
                data-cy={"pw"}
              />
            </Box>

            {/*login btn*/}
            <MainColorButton {...LOGIN_MAIN_COLOR_BTN_PROPS} />

            <div
              className={classes.findPwd}
              onClick={findPwdToggle}>
              {/*비밀번호 찾기*/}
            </div>

            <div className={classes.footer}>support@colosseum.kr / 1566-9527</div>
          </div>
        </div>
      </form>

      {findPwdOpen && (
        <FindPwd
          isOpen={findPwdOpen}
          toggle={findPwdToggle}
        />
      )}
    </div>
  );
};

export default PC_Login_Page;

export { getServerSideProps };

const useStyles = makeStyles((theme) => ({
  darkMode: {
    position: "fixed",
    top: pxFn(16),
    right: pxFn(16),
  },
  loginWrapper: {
    paddingBottom: pxFn(64),
    width: "100vw",
    height: "100vh",
    ...FLEX_CENTER_CSS,
    backgroundColor: theme.palette.background.default,
  },

  loginContainer: {
    width: pxFn(560),
    ...H_100_CSS,
    ...FLEX_CENTER_CSS,
    flexDirection: "column",
    textAlign: "center",
    backgroundColor: theme.palette.background.default,

    "& .loginTop": {
      marginBottom: pxFn(32),
    },
  },

  loginInputs: {
    padding: pxFn(32),
    width: pxFn(560),
    height: pxFn(400),
    backgroundColor: theme.palette.background.paper,
    border: `${pxFn(1)} solid ${theme.palette.divider}`,
    borderRadius: pxFn(12),
    boxShadow: theme.shadow[1],
  },

  findPwd: {
    margin: `${pxFn(16)} 0 ${pxFn(96)}`,
    color: theme.palette.text.tertiary,
    fontSize: pxToRemConvertFn(14),
    cursor: "pointer",
  },

  footer: {
    paddingBottom: pxFn(16),
    color: theme.palette.text.tertiary,
    fontSize: pxToRemConvertFn(12),
  },
}));
