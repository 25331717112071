import { useMutation } from "react-query";

import { commonOnSuccess, getAPIData } from "/common/Fn";

/**
 * - PC 메뉴 api
 * - api: /menu/${type}
 * - path: /hooks/api/useMutation/menu/useGetMenuList.js
 * @param options - mutation options
 * @example
 * const { mutate } = useGetMenuList()
 * ...
 * mutate("admin");
 */
const useGetMenuList = (options = {}) =>
  useMutation(async (type, queryData = {}) => getAPIData(`/menu/${type}`, queryData), {
    onSuccess: ({ header }) => commonOnSuccess(header),
    ...options,
  });

export default useGetMenuList;
